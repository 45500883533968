import React, { useEffect,useState  } from 'react';
import { useParams,NavLink,useNavigate } from 'react-router-dom';
import Timer from '../Sections/Timer'

function Match() {
    const [token, setToken] = useState();
    const [match, setMatch] = useState([]);
    const [startingMatch, setMatchStarting] = useState(false);
    const [cancellingMatch, setMatchCancelling] = useState(false);
    const [confirmCancelMatch, setCancelMatchConfirmation] = useState(false);
    const [confirmStartMatch, setStartMatchConfirmation] = useState(false);
    const [players, setPlayers] = useState([]);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const [time, setTime] = useState(300);

    const [referralCode, setReferralCode] = useState();

    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [refarralUrl, setReferralUrl] = useState('https://app.pacxos.com/?mode=register&r=PCX0000');
    const [logoUrl, setLogoUrl] = useState('https://media.pacxos.com/icon.png');
    const [referralCopied, setReferalCopied] = useState(false);

    const [qrData, setQrData] = useState({
        Text: 'https://app.pacxos.com/?mode=register&r=PCX0000',
        LogoUrl: 'https://media.pacxos.com/icon.png',
    });

    const navigate = useNavigate();
    
    useEffect(() => {
        ///console.log("Match details call to action..");

        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);

            if(!token){
                setToken(tokenJson.token);
            }else{
                setReferralCode(tokenJson.referralCode);
                setReferralUrl("https://app.pacxos.com/?mode=register&r="+tokenJson.referralCode);
                fetchMatch(id,tokenJson.token,tokenJson.referralCode);
            }
        } else {
            setToken('');
        }
    }, [id,token]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        return () => clearInterval(intervalId); // cleanup on unmount
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const fetchMatch = async (id,token,referralCode) => {
        ///console.log("Fetch match details");
        try {
            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/Match?id=${id}&token=${token}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMatch(data);

            if (data.matchStatusTitle === 'PENDING'){
                setReferralUrl("https://app.pacxos.com/?mode=register&r="+referralCode+"&play="+data.matchRef);
                ///generateQrCode("https://app.pacxos.com/?mode=register&r="+referralCode+"&play="+data.matchRef);
            }

            ///console.log("Match started: "+data.matchStart)

            ///GET MATCH PLAYERS
            fetchPlayers(data.id,token);
        } catch (error) {
            ////console.error('Error fetching match:', error);
        }
    };

    const generateQrCode = async (referralUrl) => {
        try {
            const updatedQrData = { ...qrData, Text: referralUrl };

            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/QrCode/generate`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                responseType: 'blob',
                body: JSON.stringify(updatedQrData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            ///const data = await response.json();
            console.log(response);
            console.log(response.blob);

            // Read the response as a Blob
            const blob = await response.blob(); // Use .blob() to get the binary data
            const url = URL.createObjectURL(blob); // Create a URL for the blob
            setQrCodeUrl(url); // Set the QR code URL
        } catch (error) {
            console.error('Error fetching Matches:', error);
        }
    };

    const fetchPlayers = async (match,token) => {
        try {
            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/Players?match=${match}&token=${token}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPlayers(data);
        } catch (error) {
            ///console.error('Error fetching players:', error);
        }
    };

    const startMatch = async (id,token) => {
        setMatchStarting(true);
        try {
            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/StartMatch?id=${id}&token=${token}`);
            if (!response.ok) {
                ////throw new Error('Network response was not ok');
                const errorResponse = await response.text();
                setMatchStarting(false);
                setError('Failed. Try again later');
            }
            ///const data = await response.json();
            fetchMatch(id,token);
            setStartMatchConfirmation(false);
            setMatchStarting(false);
        } catch (error) {
            setMatchStarting(false);
            ///console.error('Error fetching game:', error);
        }
    };

    const confirmMatchCancellation=()=>{
        setCancelMatchConfirmation(true);
    }

    const cancelMatchCancellation=()=>{
        setCancelMatchConfirmation(false);
    }

    const confirmMatchStart=()=>{
        setStartMatchConfirmation(true);
    }
    
    const cancelMatchStart=()=>{
        setStartMatchConfirmation(false);
    }

    const cancelMatch = async (id,token) => {
        setMatchCancelling(true);
        try {
            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/CancelMatch?id=${id}&token=${token}`);
            if (!response.ok) {
                ////throw new Error('Network response was not ok');
                const errorResponse = await response.text();
                setMatchCancelling(false);
                setError('Failed. Try again later');
            }
            ///const data = await response.json();
            fetchMatch(id,token);
            setMatchCancelling(false);
            navigate('/');
        } catch (error) {
            setMatchCancelling(false);
            ///console.error('Error fetching game:', error);
        }
    };

    const renderMatchButton = () => {
        if (match.matchStatusTitle === 'PENDING') {
            if (startingMatch) {
                return (
                    <button className="btn btn-dark btn-44 shadow-none">
                        <i className="bi bi-arrow-repeat"></i>
                    </button>
                );
            } else {
                return (
                    <div className='d-flex gap-2'>
                        <button onClick={()=>confirmMatchStart()}  className="btn btn-dark btn-44 shadow-none">
                            <i className="bi bi-stopwatch"></i>
                        </button>
                        <button onClick={()=>confirmMatchCancellation()} className="btn btn-danger btn-44 shadow-none">
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                );
            }
        } else if (match.matchStatusTitle === 'LIVE') {
            return(
                <NavLink to={`/end/${match.matchRef}`} className="btn btn-danger btn-44 shadow-none">
                    <i className="bi bi-stopwatch"></i>
                </NavLink>
            );
        } else {
            return (
                <NavLink to={`/`}  className="btn btn-default btn-44 shadow-none">
                    <i class="bi bi-arrow-left"></i>
                </NavLink>
            );
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(refarralUrl)
          .then(() => {
            setReferalCopied(true);
        })
          .catch(err => {
            ///console.error('Failed to copy: ', err);
            setReferalCopied(false);
        });
    };

    function formatPeriodTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString([], options);
    }

    function formatPeriodDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    function calculateDurationInHHMMSS(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const durationInMilliseconds = endDate - startDate;
    
        const totalSeconds = Math.floor(durationInMilliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 3600 % 60;
    
        const pad = (num) => String(num).padStart(2, '0');
    
        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }

    return (
        <>
            <div className='mt-5 pt-5'>
                {match.matchRef?
                    <div>
                        {
                            confirmCancelMatch?
                                <div className="card bg-danger text-dark shadow-sm shadow-danger bg-opac border mb-4">
                                    <div className="card-body">
                                        <h5 className="modal-title text-center" id="exampleModalLabel">Confirm match cancellation</h5>
                                        <p className='text-center'>Confirm you want to cancel this match. You can cancel the match when no player has joined.</p>
                                        {
                                            cancellingMatch?
                                                <p className='text-center'>PLEASE WAIT...</p>
                                            :
                                                <div className="d-flex justify-content-between">
                                                    <button type="button" className="btn btn-secondary" onClick={()=>cancelMatchCancellation()}>Cancel</button>
                                                    <button type="button" className="btn btn-danger" onClick={() => cancelMatch(match.matchRef, token)}>Confirm</button>
                                                </div>
                                        }
                                    </div>
                                </div>
                            :
                            <>
                                {
                                    confirmStartMatch?
                                        <div className="card bg-danger text-dark shadow-sm shadow-danger bg-opac border mb-4">
                                            <div className="card-body">
                                                <h5 className="modal-title text-center" id="exampleModalLabel">Confirm match start</h5>
                                                <p className='text-center'>Confirm you want to start this match. You can start the match when all players has joined.</p>
                                                {
                                                    startingMatch?
                                                        <p className='text-center'>PLEASE WAIT...</p>
                                                    :
                                                        <div className="d-flex justify-content-between">
                                                            <button type="button" className="btn btn-secondary" onClick={()=>cancelMatchStart()}>Cancel</button>
                                                            <button type="button" className="btn btn-danger" onClick={() => startMatch(match.matchRef, token)} >Confirm</button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    :
                                    <div className='d-flex gap-2 justify-content-between'>
                                        <div className='flex-grow-1'>
                                            <p className="size-10 mb-1"><span className='text-danger'><i className="bi bi-record-circle size-16 me-1" />{match.matchStatusTitle}</span></p>
                                            <p className='mb-0 size-16 fw-bold'>{match.matchRef}</p>
                                        </div>
                                        
                                        {match.matchStatusTitle === 'LIVE'?
                                            <button type="button" className="btn btn-outline-secondary px-3 shadow-none">
                                                <Timer initialDateTime={match.matchStart} />
                                            </button>
                                            :
                                            <div>
                                                {match.matchStart?
                                                    <button type="button" className="btn btn-outline-secondary px-3 shadow-none">
                                                        {calculateDurationInHHMMSS(match.matchStart, match.matchEnd)}
                                                    </button>
                                                :
                                                    <></>
                                                }
                                            </div>
                                        }
                                        {match.matchRole==='MODERATOR'?
                                            renderMatchButton()
                                            :
                                            <NavLink to={`/`}  className="btn btn-default btn-44 shadow-none">
                                                    <i class="bi bi-arrow-left"></i>
                                            </NavLink>
                                        }
                                        
                                    </div>
                                }
                            </>
                        }
                        
                        {error && <p className="text-danger my-3">{error}</p>}
                        <hr className='my-2'/>
                        <div className='d-flex justify-content-between'>
                            <p className="size-12 mb-2"><span className='text-dark'><i className="bi bi-person-circle size-16 me-1" />{match.moderatorName}</span></p>
                            <p className="text-secondary text-muted size-12 mb-2">{match.createdDate?formatPeriodDate(match.createdDate):<></>}</p>
                        </div>
                        {match.matchDescription ? <p className="text-secondary mb-3 size-14"><em>{match.matchDescription}</em></p>:<></>}
                        
                        {match.betOptions && match.betOptions.map((option, index) => {
                            const badgeColors = ['bg-blue text-white', 'bg-indigo text-white', 'bg-cyan text-white', 'bg-pink text-white', 'bg-orange text-white'];
                            // Determine the color based on the index
                            const badgeColor = badgeColors[index % badgeColors.length];
                            
                            return (
                                <span key={option.id} className={`badge ${badgeColor} rounded-10 me-2 mb-2`}>
                                    {option.name}
                                </span>
                            );
                        })}

                        <div className='row'>
                            {/* {match.moderatorName!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">{match.moderatorName}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Moderator</p>
                                </div>
                                :
                                <></>
                            } */}
                            {match.players!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">{match.players}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Players</p>
                                </div>
                                :
                                <></>
                            }
                            {match.stakeAmt!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">Ksh {match.stakeAmt}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Stake</p>
                                </div>
                                :
                                <></>
                            }
                            {match.stakeEscrowAmt!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">Ksh {match.stakeEscrowAmt}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Escrow</p>
                                </div>
                                :
                                <></>
                            }
                            {match.moderatorFee!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">Ksh {match.moderatorFee}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Moderator</p>
                                </div>
                                :
                                <></>
                            }
                            {match.moderatorChargesAmt!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">Ksh {match.moderatorChargesAmt}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Charge</p>
                                </div>
                                :
                                <></>
                            }
                            {match.winAmt!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">Ksh {match.winAmt}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Pool</p>
                                </div>
                                :
                                <></>
                            }
                            {match.matchStart!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">{match.matchStart!=null?formatPeriodTime(match.matchStart):match.matchStart}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Start</p>
                                </div>
                            :
                                <></>
                            }
                            {match.matchEnd!=null?
                                <div className='col-4 mb-2'>
                                    <p className="text-dark size-16 fw-bold mb-0">{match.matchEnd!=null?formatPeriodTime(match.matchEnd):match.matchEnd}</p>
                                    <p className="text-muted size-12 fw-bold mb-0">Ended</p>
                                </div>
                            :
                                <></>
                            }
                        </div>
                    </div>
                :
                    <div className='text-center fw-bold'>Loading...</div>
                }
                {
                    (match.matchStatusTitle === 'PENDING')?
                        <div>
                            {/* {qrCodeUrl ? 
                                <>
                                    <img src={qrCodeUrl} style={{height:'140px',width:'140px'}} alt className="mb-2" />
                                    <p className="size-12 text-secondary mb-1">Invite your peers to play by QR Code.</p>
                                </>
                            :
                                <></>
                            } */}
                            <p className="size-12 text-secondary mb-1 mt-2">Copy and share this link</p>
                            <p className='bg-light rounded-3 border p-2 d-flex gap-2 align-items-center justify-content-between text-start mb-0'><em className='size-12'>{refarralUrl}</em><i className="bi bi-clipboard" onClick={copyToClipboard} style={{ cursor: 'pointer' }}></i></p>
                            {referralCopied&&<em className='text-success size-10'>Referral link copied!!</em>}
                        </div>
                    :
                        <></>
                }
                {players?
                    <div className='mt-3 mb-5 pb-5'>
                        <div className='row'>
                            {players.map((player) => (
                                <div key={player.id} className='col-6 col-md-4 '>
                                    <div className={`card my-2 ${(match.winnerName === player.playerName || player.position === 1) ? 'border border-3 border-secondary bg-light' : ''}`}>
                                        <div className="card-body text-center">
                                            <figure className="avatar avatar-40 shadow-sm rounded-18 mb-2">
                                                <i class="bi bi-person-circle"></i>
                                            </figure>
                                            <p className="mb-0 text-center">{player.playerName}</p>
                                            {player.playerNote?<p className='text-secondary size-12 mb-0'><span>{player.playerNote}</span></p>:<p className='text-secondary size-12 mb-0'>******</p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                :
                    <div className='text-center fw-bold'></div>
                }
            </div>
        </>
    );
}

export default Match;