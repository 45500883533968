import React, { useEffect,useState } from 'react';
import { NavLink } from 'react-router-dom';
import Timer from '../Sections/Timer'

function Live() {
    const [token, setToken] = useState();
    ///const [account, setAccont] = useState([]);
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);

            if (!token) {
                setToken(tokenJson.token);
                ///setAccont(tokenJson);
            }else{
                fetchTopMatches(tokenJson.token);
            }
            // setToken(tokenJson.token);
            // setAccont(tokenJson);
            // fetchTopMatches(tokenJson.token);
        } else {
            setToken('');
        }
    }, [token]);

    const fetchTopMatches = async (tokenString) => {
        ///console.log("Getting matches list...");
        try {
            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/LiveBoard?token=${tokenString}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMatches(data);
        } catch (error) {
            console.error('Error fetching Matches:', error);
        }
    };

    function formatPeriodTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString([], options);
    }

    function formatPeriodDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }
  return (
    <>
        <div>
            <div className='mt-5 py-5'>
                <ul className='list-group list-group-flush bg-none mb-5'>
                    {matches.map((match) => (
                        <li key={match.id} className='list-group-item'>
                            <NavLink to={`/match/${match.matchRef}`} className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar avatar-50 p-1 shadow-none shadow-dark rounded-15">
                                        <div className="icons border border-danger text-danger rounded-12">
                                            <i className="bi bi-stopwatch" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col ps-0">
                                    <p className="text-secondary size-10 mb-1"><span className='text-danger me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>  {match.GameName}</p>
                                    <div className='d-flex align-items-center'>
                                        <p className='mb-0 size-12 text-dark fw-bold'>{match.matchRef}</p>
                                        <marquee className='ms-2 text-secondary flex-grow-1 size-10' behavior="" direction="rigt">{match.matchDescription}</marquee>
                                    </div>
                                </div>
                                <div className="col-auto align-self-center text-end">
                                    <p className="text-secondary text-muted size-10 mb-1">{formatPeriodDate(match.CreatedDate)}</p>
                                    <p className='mb-0 size-12 fw-bold text-dark'>
                                        {match.MatchStatusTitle==='LIVE'?
                                            <p className='mb-0 size-12 fw-bold text-dark'><Timer initialDateTime={match.matchStart} /></p>
                                        :
                                            <p className='mb-0 size-12 fw-bold text-dark'>COMMING UP</p>
                                        }
                                    </p>
                                </div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='mb-3 text-center'>
                <img src="pacxos.svg" height='50px' alt />
            </div>
        </div>
    </>
  );
}

export default Live;