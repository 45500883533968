import React, { useState,useEffect } from 'react';
///import { useSQLite } from 'react-sqlite';
///import { saveAccountDataToLocalStorage, getAuthDataFromLocalStorage } from "../../Data";

const RegisterForm = ({ onLoginSuccess }) => {
  const [referal, setReferal] = useState();
  const [formData, setFormData] = useState({
    country: '254',
    nickname: '',
    phoneNumber: '',
    otp: '',
    accountPassword: '',
    ConfirmPassword: '',
    InvitationCode: ''
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [verifyingPhone, setVerifyingPhone] = useState(false);
  const [verifyOtp, setOtpVerification] = useState(false);
  const [verifyingOTP, setVerifyingOTP] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const referalCode = localStorage.getItem('referalCode');
    if (referalCode) {
      console.log("Invited by: "+ referalCode);

      setReferal(referalCode);
    }
  }, []);
  
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisible = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validatePhone = async (e) => {
    e.preventDefault();
    setVerifyingPhone(true);
    try {
        const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/ValidatePhone`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) {
          const errorResponse = await response.text();
          setError('Phone Verification failed,'+ errorResponse);
          setVerifyingPhone(false);
          return; // Exit the function if there's an error
        } 

        //const data = await response.json();
        ////console.log(data);
        ///saveAccountDataToLocalStorage(data);
        ///const jsonData = JSON.stringify(data);
        ///localStorage.setItem("pacxosData", jsonData);
        setOtpVerification(true);
        setVerifyingPhone(false);
    } catch (error) {
      setError('Phone Verification failed. Try again later');
      ////console.error('Error:', error);
      setVerifyingPhone(false);
    }
  };

  const reSendOTP = async (e) => {
    e.preventDefault();
    setVerifyingOTP(true);
    try {
        const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/ReSendOTP`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) {
          const errorResponse = await response.text();
          setError('OTP Verification failed, '+errorResponse);
          setVerifyingOTP(false);
          setOtpVerification(false);
          return; // Exit the function if there's an error
        } 

        //const data = await response.json();
        ////console.log(data);
        ///saveAccountDataToLocalStorage(data);
        ///const jsonData = JSON.stringify(data);
        ///localStorage.setItem("pacxosData", jsonData);
        setVerifyingOTP(false);
        setOtpVerification(true);
    } catch (error) {
      setError('OTP Verification failed. Try again later');
      ////console.error('Error:', error);
      setVerifyingOTP(false);
      setOtpVerification(false);
    }
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    setVerifyingOTP(true);
    try {
        const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/VerifyOTP`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) {
          const errorResponse = await response.text();
          setError('OTP Verification failed, '+errorResponse);
          setVerifyingOTP(false);
          return; // Exit the function if there's an error
        } 

        //const data = await response.json();
        ////console.log(data);
        ///saveAccountDataToLocalStorage(data);
        ///const jsonData = JSON.stringify(data);
        ///localStorage.setItem("pacxosData", jsonData);
        setOtpVerification(false);
        setVerifyingOTP(false);
        setPhoneVerified(true);
    } catch (error) {
      setError('OTP Verification failed. Try again later');
      ////console.error('Error:', error);
      setVerifyingOTP(false);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(phoneVerified){
      setLoggingIn(true);
      try {
          const updatedFormData = { ...formData, InvitationCode: referal };

          const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/Account`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedFormData)
          });
  
          if (!response.ok) {
            const errorResponse = await response.text();
            setError('Failed. Try again later');
            setLoggingIn(false);
            return; // Exit the function if there's an error
          } 
  
  
          const data = await response.json();
          ////console.log(data);
          ///saveAccountDataToLocalStorage(data);
          const jsonData = JSON.stringify(data);
          localStorage.setItem("pacxosData", jsonData);
          onLoginSuccess();
      } catch (error) {
        setError('Sign Up failed. Try again later');
        ////console.error('Error:', error);
        setLoggingIn(false);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h1 className="mb-4"><span className="text-secondary fw-light">Create</span><br />account</h1>  
        {error && <small className="text-danger my-3">{error}</small>}
        {phoneVerified?
          <div>
            <small>Enter nicknane & Password</small>
            <div className="form-floating mb-3">
                <input type="text" className="form-control" placeholder="Unique name" name='nickname' value={formData.nickname} onChange={handleChange} id="nickname" />
                <label htmlFor="nickname">Nickname</label>
            </div>
            <div className="form-floating mb-3">
                <input type={isPasswordVisible ? 'text' : 'password'} className="form-control" placeholder="Password" name='accountPassword' value={formData.accountPassword} onChange={handleChange} id="accountPassword" />
                <label htmlFor="accountPassword">Password</label>
                <button type="button" onClick={togglePasswordVisibility} className="btn btn-link text-muted tooltip-btn">
                    <i className={isPasswordVisible ? "bi bi-eye-slash" : "bi bi-eye"} />
                </button>
            </div>
            <div className="form-floating mb-3">
                <input type={isConfirmPasswordVisible ? 'text' : 'password'} className="form-control" placeholder="Confirm Password" name='ConfirmPassword' value={formData.ConfirmPassword} onChange={handleChange} id="confirmpassword" />
                <label htmlFor="confirmpassword">Confirm Password</label>
                <button type="button" onClick={toggleConfirmPasswordVisible} className="btn btn-link text-danger tooltip-btn">
                  <i className={isConfirmPasswordVisible ? "bi bi-eye-slash" : "bi bi-eye"} />
                </button>
            </div>
            <p className="mb-3"><span className="text-muted">By clicking on Sign up button, you are agree to the our </span> <a href>Terms and Conditions</a></p>
            
            <div className="row my-3">
                <div className="col-12 d-grid">
                  {loggingIn?
                    <div className='text-center text-muted'>Creating Account...</div>
                    :
                    <button type="submit" className="btn btn-default btn-lg shadow-sm">Sign Up</button>
                  }
                </div>
            </div>
          </div>
        :
          <div>
            {verifyOtp?
                <div>
                  <div className='mt-3'>
                    <div className='d-flex'>
                      <div className='flex-grow-1'>
                        <p className='mb-0'>OTP</p>
                        <small>Verify your phone number, enter OTP send to you by SMS.</small>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <input type="tel" className="form-control" placeholder="OTP" name='otp' value={formData.otp} onChange={handleChange} id="otp" />
                      <label htmlFor="otp">OTP</label>
                    </div>
                  </div>
                  {verifyingOTP?
                    <div className='text-center text-muted'>Verifying OTP...</div>
                    :
                    <div className='d-flex justify-content-between gap-2'>
                      <button type="button" onClick={verifyOTP} className="btn btn-default btn-lg shadow-sm">Verify</button>
                      <button type="button" onClick={reSendOTP} className="btn btn-dark btn-sm shadow-sm">Resend</button>
                    </div>
                  }
                </div>
              :
                <div>
                  <small>Validate your phone number</small>
                  <div className="form-floating mb-3">
                    <select className="form-control" id="country" name="country" value={formData.country} onChange={handleChange}>
                        <option value='254' selected>Kenya (+254)</option>
                        <option value='255'>Tanzania (+255)</option>
                        <option value='256'>Uganda (+256)</option>
                    </select>
                    <label htmlFor="country">Contry</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="tel" className="form-control" placeholder="Phone Number" name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} id="phoneNumber" />
                    <label htmlFor="phoneNumber">Phone Number</label>
                  </div>
                  {verifyingPhone?
                    <div className='text-center text-muted'>Validating Phone Number...</div>
                    :
                    <button type="button" onClick={validatePhone} className="btn btn-default btn-lg shadow-sm">Validate</button>
                  }
                </div>
            }
          </div>
        }
      </form>
    </div>
  );
};

export default RegisterForm;
