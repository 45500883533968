import React, { useState,useEffect } from 'react';
///import { useSQLite } from 'react-sqlite';
///import { saveAccountDataToLocalStorage, getAuthDataFromLocalStorage } from "../../Data";

const ChangePassword = () => {
  const [token, setToken] = useState();
  const [formData, setFormData] = useState({
    token: '',
    accountPassword: '',
    newPassword: '',
    ConfirmPassword: ''
  });

  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const jsonData = localStorage.getItem('pacxosData');
    if (jsonData) {
        const tokenJson = JSON.parse(jsonData);
        console.log(tokenJson);
        setToken(tokenJson.token);
        setFormData({ ...formData, ['token']: tokenJson.token });
    } else {
        setToken('');
    }
  }, []);
  
  const toggleOldPasswordVisibility = () => {
    setIsOldPasswordVisible(!isOldPasswordVisible);
  };
  
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisible = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setChangingPassword(true);

    if(formData.ConfirmPassword!==formData.newPassword){
      setError('Failed. Confirm Password');
      setChangingPassword(false);
      return;
    }

    try {
        const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/ChangePassword`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) {
          const errorResponse = await response.text();
          setError('Failed. Try again later');
          setChangingPassword(false);
          return; // Exit the function if there's an error
        } 

        setChangingPassword(false);
        ///onLoginSuccess();
    } catch (error) {
      setError('Sign Up failed. Try again later');
      ////console.error('Error:', error);
      setChangingPassword(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {error && <small className="text-danger my-3">{error}</small>}
        <small className='mb-3'>Change account password</small>

        <div className="form-floating mb-3">
            <input type={isOldPasswordVisible ? 'text' : 'password'} className="form-control" placeholder="Current Password" name='accountPassword' value={formData.accountPassword} onChange={handleChange} id="accountPassword" />
            <label htmlFor="accountPassword">Current Password</label>
            <button type="button" onClick={toggleOldPasswordVisibility} className="btn btn-link text-muted tooltip-btn">
                <i className={isOldPasswordVisible ? "bi bi-eye-slash" : "bi bi-eye"} />
            </button>
        </div>
        <div className="form-floating mb-3">
            <input type={isPasswordVisible ? 'text' : 'password'} className="form-control" placeholder="New Password" name='newPassword' value={formData.newPassword} onChange={handleChange} id="accountPassword" />
            <label htmlFor="accountPassword">New Password</label>
            <button type="button" onClick={togglePasswordVisibility} className="btn btn-link text-muted tooltip-btn">
                <i className={isPasswordVisible ? "bi bi-eye-slash" : "bi bi-eye"} />
            </button>
        </div>
        <div className="form-floating mb-3">
            <input type={isConfirmPasswordVisible ? 'text' : 'password'} className="form-control" placeholder="Confirm New Password" name='ConfirmPassword' value={formData.ConfirmPassword} onChange={handleChange} id="confirmpassword" />
            <label htmlFor="confirmpassword">Confirm Password</label>
            <button type="button" onClick={toggleConfirmPasswordVisible} className="btn btn-link text-muted tooltip-btn">
                <i className={isPasswordVisible ? "bi bi-eye-slash" : "bi bi-eye"} />
            </button>
        </div>
        <div className="row ">
            <div className="col-12 d-grid">
                {changingPassword?
                  <div className='text-center text-muted my-3'>Saving...</div>
                :
                  <button type="submit" className="btn btn-default btn-lg shadow-sm mt-3">Save Password</button>
                }
            </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
