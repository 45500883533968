import React, { useEffect,useState } from 'react';
///import * as signalR from "@microsoft/signalr";
import ArenaListView from './List';
import ArenaMapView from './Map';
////import { NavLink } from 'react-router-dom';

function Directory() {
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [token, setToken] = useState();
    const [error, setError] = useState(null);
    const [arenas, setArenas] = useState([]);
    const [viewMode, setViewMode] = useState('map'); 
    
    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            setToken(tokenJson.token);
            ///console.log(tokenJson.token);

            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLocation({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        });
                        setError('');

                        // console.log(location);
                        // console.log(location+" - ARENAS");
                        fetchArenas(tokenJson.token,location.latitude,location.longitude);
                    },
                    (error) => {
                        handleLocationError(error);
                        fetchArenas(tokenJson.token,'','');
                    }
                );
            } else {
                console.log('Geolocation is not supported by your browser.');
                fetchArenas(tokenJson.token,'','');
                setError('Geolocation is not supported by your browser.');
            }
        } else {
            setToken('');
        }
    }, []);

    const handleLocationError = (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            setError('User denied the request for Geolocation.');
            break;
          case error.POSITION_UNAVAILABLE:
            setError('Location information is unavailable.');
            break;
          case error.TIMEOUT:
            setError('The request to get user location timed out.');
            break;
          case error.UNKNOWN_ERROR:
            setError('An unknown error occurred.');
            break;
          default:
            setError('An unknown error occurred.');
            break;
        }
      };

    const fetchArenas = async (tokenString,lat,lng) => {
        try {
            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/Arena?token=${tokenString}&lat=${lat}&lng=${lng}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setArenas(data);
        } catch (error) {
            ////console.error('Error fetching arenas:', error);
        }
    };

    const toggleViewMode = (mode) => {
        setViewMode(mode);
    };

    return (
        <>
            <div>
                <div className="row mt-5 pt-5 mb-3">
                    <div className="col align-self-center">
                        <h6 className="title">ARENA</h6>
                        {/* <p className="text-secondary small">{arenas.length} listed</p> */}
                    </div>
                    <div className="col-auto">
                        {viewMode === 'list' ? (
                            <nav>
                                <div className="nav nav-tabs border-0 gap-3" id="nav-tab" role="tablist">
                                    <button onClick={() => toggleViewMode('list')} className="btn btn-44 btn-outline-dark ms-2 cursor-pointer active">
                                        <i onClick={() => toggleViewMode('list')} className="bi bi-list" />
                                    </button>
                                    <button onClick={() => toggleViewMode('map')} className="btn btn-44 btn-outline-dark cursor-pointer">
                                        <i onClick={() => toggleViewMode('map')} className="bi bi-geo-alt" />
                                    </button>
                                </div>
                            </nav>
                        ) : (
                            <nav>
                                <div className="nav nav-tabs border-0 gap-3" id="nav-tab" role="tablist">
                                    <button onClick={() => toggleViewMode('list')} className="btn btn-44 btn-outline-dark ms-2 cursor-pointer">
                                        <i onClick={() => toggleViewMode('list')} className="bi bi-list" />
                                    </button>
                                    <button onClick={() => toggleViewMode('map')} className="btn btn-44 btn-outline-dark cursor-pointer active">
                                        <i onClick={() => toggleViewMode('map')} className="bi bi-geo-alt" />
                                    </button>
                                </div>
                            </nav>
                        )}
                    </div>
                    {/* <div className="col-12 text-center mt-3">
                        <p className="text-secondary">Find Pool arenas near you.</p>
                    </div> */}
                </div>
                {error && <p className="text-danger my-3">{error}</p>}
                {viewMode === 'list' ? (
                    <ArenaListView arenas={arenas} />
                ) : (
                    <ArenaMapView arenas={arenas} />
                )}
            </div>
        </>
    );
}

export default Directory;