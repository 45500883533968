import React, { useEffect,useState } from 'react';
import { NavLink } from 'react-router-dom';
import Timer from '../Sections/Timer'

function History() {
    const [token, setToken] = useState();
    const [account, setAccont] = useState([]);
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');
        if (jsonData) {
            const tokenJson = JSON.parse(jsonData);
            
            if(!token){
                setToken(tokenJson.token);
            }else{
                setAccont(tokenJson);
            
                fetchTopMatches(tokenJson.token);
            }
        } else {
            setToken('');
        }
    }, [token]);

    const fetchTopMatches = async (tokenString) => {
        try {
            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/Matches?token=${tokenString}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMatches(data);
        } catch (error) {
            console.error('Error fetching Matches:', error);
        }
    };

    function formatPeriodTime(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { hour: '2-digit', minute: '2-digit' };
        return date.toLocaleTimeString([], options);
    }

    function formatPeriodDate(dateTimeString) {
        const date = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    function calculateDurationInHHMMSS(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const durationInMilliseconds = endDate - startDate;
    
        const totalSeconds = Math.floor(durationInMilliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 3600 % 60;
    
        const pad = (num) => String(num).padStart(2, '0');
    
        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    }

    return (
        <>
            <div>
                <div className='mt-5 py-5'>
                    <ul className='list-group list-group-flush bg-none mb-5'>
                        {matches.map((match) => (
                            <li key={match.id} className='list-group-item'>
                                <NavLink to={`/match/${match.matchRef}`} className="row align-items-center">
                                    <div className="col-auto">
                                        {match.MatchStatusTitle==='LIVE'?
                                            <div className="avatar avatar-50 p-1 shadow-none rounded-15">
                                                <div className="icons border border-danger text-danger rounded-12">
                                                    <i className="bi bi-stopwatch" />
                                                </div>
                                            </div>
                                        :
                                            <div>
                                                {match.IsModerator==='MODERATOR'?
                                                    <div className="avatar avatar-50 p-1 shadow-none rounded-15">
                                                        {match.IsWinner==='WINNER'?
                                                            <div className="icons bg-success text-white rounded-12">
                                                                <i className="bi bi-shield-shaded" />
                                                            </div>
                                                        :
                                                            <>
                                                                {match.IsPlayer==='PLAYER'?
                                                                    <div className="icons bg-danger text-white rounded-12">
                                                                        <i className="bi bi-shield-shaded" />
                                                                    </div>
                                                                :
                                                                    <div className="icons bg-dark text-white rounded-12">
                                                                        <i className="bi bi-shield-shaded" />
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                :
                                                    <div className="avatar avatar-50 p-1 shadow-none rounded-15">
                                                        {match.IsWinner==='WINNER'?
                                                            <div className="icons bg-success text-white rounded-12">
                                                                <i className="bi bi-award" />
                                                            </div>
                                                        :
                                                            <div className="icons bg-danger text-white rounded-12">
                                                                <i className="bi bi-slash-circle" />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="col-auto ps-0">
                                        <p className="text-secondary size-10 mb-1">
                                            {match.MatchStatusTitle==='LIVE'?
                                                <span className='text-danger me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>
                                            : match.MatchStatusTitle==='PENDING'?
                                                <span className='text-warning me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>
                                            : match.MatchStatusTitle==='SETTLED'?
                                                <span className='text-dark me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>
                                            :
                                                <span className='text-muted me-2'><i className="bi bi-record-circle size-16 me-1" />{match.MatchStatusTitle}</span>
                                            }
                                            {match.players} Players
                                        </p>
                                        <p className='mb-0 size-12 text-dark fw-bold'>{match.matchRef} <span className='mx-2'>|</span>Ksh. {match.winAmt}</p>
                                    </div>
                                    <div className="col align-self-center text-end">
                                        <p className="text-secondary text-muted size-10 mb-1">{formatPeriodDate(match.matchStart)}</p>
                                        {match.MatchStatusTitle==='LIVE'?
                                            <p className='mb-0 size-12 fw-bold text-dark'><Timer initialDateTime={match.matchStart} /></p>
                                        :
                                            <p className='mb-0 size-12 fw-bold text-dark'>{calculateDurationInHHMMSS(match.matchStart, match.matchEnd)}</p>
                                        }
                                    </div>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='mb-3 text-center'>
                    <img src="pacxos.svg" height='50px' alt />
                </div>
            </div>
        </>
    );
}

export default History;